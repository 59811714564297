import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { createPinia } from "pinia";

import VueFlatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

createApp(App)
    .use(router)
    .use(createPinia())
    .use(VueFlatPickr)
    .mount('#app')
