<template>
  <div class="login-container">
    <form
      @submit.prevent="login"
      class="m-3 d-grid gap-1 mx-auto"
      style="max-width: 375px"
    >
      <h2 class="text-center" style="font-family: system-ui">
        <info-text
          desc="ZoloSCAN Dashboard of Fleet System Performance and Health Checks"
        >
          Zolo System Health
        </info-text>
      </h2>

      <input
        placeholder="Username"
        v-model="username"
        class="form-control"
        type="text"
        required
      />

      <input
        placeholder="Password"
        v-model="password"
        class="form-control"
        type="password"
        required
      />

      <button type="submit" class="btn btn-primary" :disabled="loggingIn">
        Login
      </button>
    </form>
  </div>
</template>

<style scoped>
.login-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
</style>

<script>
import store from "@/store";

import InfoText from "@/components/InfoText.vue";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      loggingIn: false,
    };
  },
  components: { InfoText },
  created() {
    document.title = "System Health | Login";
  },
  methods: {
    login() {
      this.loggingIn = true;

      store.login(this.username, this.password).then((token) => {
        this.loggingIn = false;

        if (token !== "unauthorized") {
          store.token = token;
          localStorage.token = token;

          // Login successful, redirect to Sites overview
          this.$router.replace({
            name: "Sites",
          });
        }
      });
    },
  },
};
</script>
