const store = {
    apiRoot: process.env.VUE_APP_API_GATEWAY_URL,

    items: null,
    datesBySiteId: {}, // by site ID
    itemsBySiteIdAndDate: {},
    overview: null,
    token: localStorage.token,

    async login(username, password) {
        const response = await fetch(`${this.apiRoot}/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password })
        })

        return response.json()
    },

    async getItems() {
        if (!this.items) {
            const response = await fetch(`${this.apiRoot}/items`,
                { headers: { 'Authorization': `Basic ${btoa(this.token)}` } }
            )

            const json = response.json().Items

            this.items = json
        }

        return this.items
    },

    getItemDates(siteId) {
        return new Promise((resolve, reject) => {
            if (this.datesBySiteId[siteId])
                resolve(this.datesBySiteId[siteId])
            else
            {
                var encodedSiteId = encodeURIComponent(siteId);
                fetch(`${this.apiRoot}/items/dates/${encodedSiteId}`, { headers: { 'Authorization': `Basic ${btoa(this.token)}` } })
                    .then(response => {
                        response.json()
                            .then(dates => {
                                this.datesBySiteId[siteId] = dates

                                resolve(dates)
                            })
                            .catch(error => {
                                reject(error)
                            })
                    })
                    .catch(error => {
                        reject(error)
                    })
            
            }
        })
    },

    getItem(siteId, date) {
        return new Promise((resolve, reject) => {
            if (this.itemsBySiteIdAndDate[siteId] && this.itemsBySiteIdAndDate[siteId][date])
                resolve(this.itemsBySiteIdAndDate[siteId][date])
            else
            {
                var encodedSiteId = encodeURIComponent(siteId);
                fetch(`${this.apiRoot}/items/${encodedSiteId}/${date}`, { headers: { 'Authorization': `Basic ${btoa(this.token)}` } })
                    .then(response => {
                        response.json()
                            .then(item => {
                                // TODO: The population of `EnabledConstituents` can be phased out once every item in the DB has them in a proper format
                                if (item.DailySummary && item.DailySummary.DailyMonitors && Object.keys(item.DailySummary.DailyMonitors).length) {
                                    const pathEntry = Object.values(item.DailySummary.DailyMonitors)[0]

                                    item.DailySummary.EnabledConstituents = Object.keys(pathEntry.ConstituentMRResults)

                                    const desiredOrder = ['H2O', 'O2', 'CO', 'CH4', 'CO2']

                                    item.DailySummary.EnabledConstituents.sort((a, b) => desiredOrder.indexOf(a) - desiredOrder.indexOf(b))
                                }

                                if (!this.itemsBySiteIdAndDate[siteId])
                                    this.itemsBySiteIdAndDate[siteId] = {}

                                this.itemsBySiteIdAndDate[siteId][date] = item

                                resolve(item)
                            })
                            .catch(error => {
                                reject(error)
                            })
                    })
                    .catch(error => {
                        reject(error)
                    })
            }
                
        })
    },

    getOverview() {
        return new Promise((resolve, reject) => {
            fetch(`${this.apiRoot}/items/overview`, { headers: { 'Authorization': `Basic ${btoa(this.token)}` } })
                .then(response => {
                    response.json()
                        .then(overview => {
                            this.overview = overview

                            resolve(overview)
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
                .catch(error => {
                    reject(error)
                })
        })
    }, 
    deleteSite(siteId) {
        var response = confirm(`This action cannot be undone. Are you sure you want to continue and delete ${siteId} and its records from the database?`);
        if (response === true)
        {
            return new Promise((resolve, reject) => {
                var encodedSiteId = encodeURIComponent(siteId);
                fetch(`${this.apiRoot}/items/${encodedSiteId}`, { method: 'DELETE', headers: { 'Authorization': `Basic ${btoa(this.token)}` } })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error)
                    });
            });
        }
    },
    addSiteTag(siteId, tagValue) {
        return new Promise((resolve, reject) => {
            var encodedSiteId = encodeURIComponent(siteId);
            fetch(`${this.apiRoot}/items/tags/${encodedSiteId}`, { 
                method: "POST", 
                headers: {
                    //'Content-Type': 'application/json',
                    'Authorization': `Basic ${btoa(this.token)}`,
                    // 'mode': 'no-cors'
                    // 'Access-Control-Allow-Origin': 'http://localhost:8080',
                    // 'Access-Control-Allow-Headers': '*',
                    // 'Access-Control-Allow-Credentials': true,
                    // 'Access-Control-Allow-Methods': "DELETE,GET,OPTIONS,PATCH,POST,PUT"

                },
                body: JSON.stringify({
                    tagValue: tagValue
                })
            })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error)
                })
        });
    },
    removeSiteTag(siteId, tagIndex) {
        return new Promise((resolve, reject) => {
            var encodedSiteId = encodeURIComponent(siteId);
            fetch(`${this.apiRoot}/items/tags/${encodedSiteId}/${tagIndex}`, { 
                method: "DELETE", 
                headers: { 'Authorization': `Basic ${btoa(this.token)}` }
            })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
        });
    }
}

function btoa(data) {
    // A helper that returns Base64 characters and their indices.
    var chars = {
        ascii: function () {
            return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
        },
        indices: function () {
            if (!this.cache) {
                this.cache = {};
                var ascii = chars.ascii();

                for (var c = 0; c < ascii.length; c++) {
                    var chr = ascii[c];
                    this.cache[chr] = c;
                }
            }
            return this.cache;
        }
    };

    var ascii = chars.ascii(),
        len = data.length - 1,
        i = -1,
        b64 = '';

    while (i < len) {
        var code = data.charCodeAt(++i) << 16 | data.charCodeAt(++i) << 8 | data.charCodeAt(++i);
        b64 += ascii[(code >>> 18) & 63] + ascii[(code >>> 12) & 63] + ascii[(code >>> 6) & 63] + ascii[code & 63];
    }

    var pads = data.length % 3;
    if (pads > 0) {
        b64 = b64.slice(0, pads - 3);

        while (b64.length % 4 !== 0) {
            b64 += '=';
        }
    }

    return b64;
};

export default store