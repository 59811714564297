<template>
  <abbr :title="desc">
    <slot></slot>
    <i class="bi bi-question-circle-fill text-info"></i>
  </abbr>
</template>

<style scoped>
abbr {
  opacity: 0.9;
}

abbr:hover {
  opacity: 1;
}

.bi.bi-question-circle-fill {
  font-size: 75%;
  vertical-align: top;
}
</style>

<script>
export default {
  name: "InfoText",
  props: ["desc"],
};
</script>
