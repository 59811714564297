import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login.vue'

import store from "@/store"

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Sites',
    component: () => import(/* webpackChunkName: "sites" */ '@/views/Sites.vue')
  },
  {
    path: '/sites/:siteId/:date',
    name: 'Site Data',
    component: () => import(/* webpackChunkName: "site-data" */ '@/views/SiteData.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from) => {
  if (!store.token && to.name !== 'Login')
    return { name: 'Login' }
})

export default router
